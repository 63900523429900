"use client";
import { redirect } from "next/navigation";
import { useAuth } from "@aether/shared/context/auth";
import { useEffect } from "react";
import { LoginForm } from "@/components/organisms/login-form";

export default function Home() {
  const { user, isLoading } = useAuth();

  useEffect(() => {
    if (user && !isLoading) {
      return redirect("/dashboard");
    }
  }, [user, isLoading]);

  return (
    <main className="container p-10 mx-auto gap-6 flex flex-col md:flex-row justify-center mt-20">
      <div className="space-y-6 text-center">
        <h1 className="text-5xl md:text-5xl font-bold leading-normal text-sky-500 ">
          Discover, Capture, Inspire with AetherLenz
        </h1>
        <h3 className="text-lg md:text-2xl  leading-loose text-neutral-800">
          Look for photography captains, ask for inspiring shots, and find your
          photos here in seconds.
        </h3>
      </div>
      <div className="min-w-md max-w-lg h-fit w-full border p-6 rounded-lg shadow-lg mx-auto">
        <LoginForm />
      </div>
    </main>
  );
}
